export const getName = (first = '', last = '', company = false) => {
    let name = ''
    if (first === '' && last === '') {
        if (company) {
            name = company
        }
    } else if (first === '' && last !== '') {
        name = last
    } else if (first !== '' && last === '') {
        name = first
    } else {
        name = first + ' ' + last
    }

    return name.trim()
}
